.footer{
  width: 100%;
  // margin-top: 150px;
  height: 272px;
  background: #222222;
  .footer-content{
    margin: 0 auto;
    width: 1440px;
    padding-top: 40px;
    .footer-contents-product{
      float: left;
      margin-left: 65px; 
      text-align: start;
      .product-title{
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
      }
      .product-content{
        margin-top: 6px;
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px;
      }
      #link {
        color: #CCC;
        cursor: pointer;
        &:hover{
          color: #EB3A32;
        }
      }
    }
    .footer-contents-contact{
      float: left;
      margin-left: 32px;
      text-align: start;
      margin-bottom: 10px;
      .contact-title{
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
      }
      .contact-content{
        margin-top: 6px;
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px; 
        white-space: nowrap;
      }
      .line-box {
        display: flex;
        width: 250px;
        gap: 32px;
      }
      #more {
        color: #666;
      }
    }
    .footer-code-left{
      float: left;
      margin-left: 95px;
      padding-top: 10px;
      .footer-icon{
        width: 104px;
        height: 104px;
      }
      .footer-icon-tip{
        margin-top: 12px;
        text-align: center;
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px;
      }
    }
    .footer-code-center{
      float: left;
      margin-left: 48px;
      padding-top: 10px;
      .footer-icon{
        width: 104px;
        height: 104px;
        background-size: 100% 100%;
      }
      .footer-icon-tip{
        margin-top: 12px;
        text-align: center;
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px;
      }
    }
    .footer-code-right{
      float: left;
      margin-left: 48px;
      padding-top: 10px;
      .footer-icon{
        width: 104px;
        height: 104px;
        background-size: 100% 100%;
      }
      .footer-icon-tip{
        margin-top: 12px;
        text-align: center;
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px;
      }
    }
    .footer-contents-address{
      // margin-top: -10px;
      float: left;
      margin-left: 72px;
      .footer-contents-phone{
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 54px;
      }
      .footer-contents-company{
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ABABAB;
        line-height: 30px;
      }
    }
  }
  .hr{
    background: #7e7e7e;
    height: 1px;
    margin: 10px 0;
  }
  .footer-footer{
    margin: 0 auto;
    width: 1440px;
    .footer-left{
      float: left;
      margin-left: 210px;
      font-size: 15px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      a {
        color: #FFFFFF;
      }
    }
    .footer-center{
      float: left;
      margin-left: 67.5px;
      font-size: 15px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }
    .footer-right{
      float: left;
      margin-left: 67.5px;
      font-size: 15px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;