#total-header {
  background: url("https://oss.huizustore.com/8dc59fe7ddf04b62a5bd989cb0e8eb60.png");
  background-size: 100% 100%;
  width: 1440px;
  height: 423px;
}

.abboutPage{
  min-width: 1440px;
  position: relative;
  .header-title{
    height: 450px;
    background: hsl(0, 0%, 97%);
    .header-pic{
      position: relative;
      width: 1440px;
      height: 450px;
      margin: 0 auto;
      background-image: url("https://oss.huizustore.com/8dc59fe7ddf04b62a5bd989cb0e8eb60.png");
      background-size: 100% 100%;
    }
    .title{
      padding-top: 127.5px;
      // margin-left: 517.5px;
      width: 405px;
      margin: 0 auto;
      font-size: 45px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .about{
    display: flex;
    justify-content: center;
    column-gap: 115px;
    padding-bottom: 120px;
    width: 1050px;
    margin: 0 auto;
    &-img {
      width: 459px;
      height: 459px;
      margin-top: -46px;
      position: relative;
      z-index: 9;
    }
    &-detail {
      margin-top: 95px;
      &-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 31px;
        color: #333333;
        line-height: 43px;
        &::after {
          content: '';
          width: 45px;
          height: 4px;
          background-color: #ECCBA8;
          display: block;
          margin-top: 2px;
        }
      }
      &-describe {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        margin-top: 13px;
      }
      &-info {
        width: 475px;
        white-space: pre-wrap;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        margin-top: 42px;
      }
    }
  }
  .boss{
    text-align: center;
    background-color: #F9F8F8;
    padding: 90px 30px;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 43px;
      &::after {
        content: '';
        width: 40px;
        height: 4px;
        background-color: #ECCBA8;
        display: block;
        margin: 0 auto;
        margin-top: 2px;
      }
    }
    &-describe {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      width: 792px;
      margin: 0 auto;
      margin-top: 13px;
    }
    &-list {
      display: flex;
      column-gap: 18px;
      margin-top: 60px;
      justify-content: center;
      .boss-item {
        background-color: #FFF;
        padding: 42px;
        text-align: center;
        border-radius: 18px;
        &-img {
          width: 200px;
          height: 238px;
          margin:  0 auto;
        }
        &-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 21px;
          color: #333333;
          line-height: 30px;
          text-align: start;
        }
        &-position {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 17px;
          margin-top: 4px;
          text-align: start;
        }
        &-info {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 21px;
          width: 255px;
          margin-top: 20px;
          text-align: start;
        }
      }
    }
  }
  .gallery {
    text-align: center;
    padding: 90px 30px;
    position: relative;
    &_title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 43px;
      &::after {
        content: '';
        width: 40px;
        height: 4px;
        background-color: #ECCBA8;
        display: block;
        margin: 0 auto;
        margin-top: 2px;
      }
    }
    &_describe {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 13px;
    }
    &_content {
      width: 970px;
      margin: 0 auto;
      .icon-left {
        width: 45px;
        height: 45px;
        background: url("https://oss.huizustore.com/7a68bfa4c54042e2a5a930b79780dab0.png");
        background-size: 100% 100%;
        position: absolute;
        top: 411px;
        left: 13%;
        z-index: 9;
      }
      .icon-right {
        width: 45px;
        height: 45px;
        background: url("https://oss.huizustore.com/ba853914eac0439bb4dc77e94f6a12cb.png");
        background-size: 100% 100%;
        position: absolute;
        top: 411px;
        right: 13%;
        z-index: 9;
      }
      .img-box {
        height: 352px;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
      .center {
        display: flex;
      }
      .slick-track {
        display: flex ;
      }
      .slider-container {
        width: 970px;
        overflow: hidden;
        margin-top: 80px;
      }
      .slick-list {
        margin: 0 -50px !important;
      }
      .slick-current {
        scale: 1.2;
      }
      .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .huizu {
    text-align: center;
    padding: 120px 40px;
    background-color: #F9F8F8;
    &_title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 43px;
      &::after {
        content: '';
        width: 40px;
        height: 4px;
        background-color: #ECCBA8;
        display: block;
        margin: 0 auto;
        margin-top: 2px;
      }
    }
    &_describe {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 13px;
    }
    &_content {
      display: flex;
      column-gap: 71px;
      justify-content: center;
      width: 1050px;
      margin: 0 auto;
      &_left {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &_item {
          width: 437px;
          text-align: start;
          &_title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
          }
          &_info {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            line-height: 21px;
            text-align: start;
          }
        }
      }
      &_right {
        width: 512px;
        height: 471px;
        img {
          width: 512px;
          height: 471px;
        }
      }
    }
  }
  .value {
    text-align: center;
    padding: 90px 30px;
    padding-bottom: 150px;
    &_title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 31px;
      color: #333333;
      line-height: 43px;
      &::after {
        content: '';
        width: 40px;
        height: 4px;
        background-color: #ECCBA8;
        display: block;
        margin: 0 auto;
        margin-top: 2px;
      }
    }
    &_describe {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 13px;
    }
    &_content {
      display: flex;
      column-gap: 16px;
      justify-content: center;
    }
    &_item {
      width: 254px;
      height: 115px;
      background-color: #F9F8F8;
      text-align: start;
      padding: 28px 22px 0 30px;
      margin-top: 60px;
      &_title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }
      &_info {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        margin-top: 6px;
      }
    }
  }
  .modalReport{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
    } 
    .ant-modal-header{
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
    }
    .ant-modal-title {
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
    }
    .ant-modal-body{
      // padding-top: 12px;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 18px;
        // line-height: 40.5px;
      }
      .icon{
        text-align: center;
        margin: 0 auto;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        padding-top: 30px;
        .icon-pic{
          margin: 0 auto;
          width: 240px;
          height: 240px;
          background-image: url('../../../官网切图/二维码/风控报告.png');
          background-size: 100% 100%;
        }
        .icon-title{
          margin-top: 10px;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #3B6FEF;
          line-height: 31.5px;
        }
      }
      .icon-tip{
        margin-top: 14px;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 31.5px;
        text-align: center;
      }
    }
  }
  .modalRevice{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 930px;
      height: 390px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .background{
        .title{
          font-size: 27px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40.5px;
          text-align: center;
        }
        .icons{
          margin: 0 auto;
          width: 876px;
          height: 270px;
          margin-left: 26px;
          margin-top: 24px;
          .icon-left{
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .left-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/首页/二维码.png");
              background-size: 100% 100%;
            }
            .left-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
          .icon-center{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .center-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/IOS.png");
              background-size: 100% 100%;
            }
            .center-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
              .tip-small{
                margin-top: -10px;
                font-size: 10px;
              }
            }
          }
          .icon-right{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .right-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/安卓.png");
              background-size: 100% 100%;
            }
            .right-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
        }
      }
    } 
  }
  .modalLease{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 660px;
      height: 770px;
      box-shadow: 0px 0px 4px 4px rgba(57, 59, 65, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .modalContainer{
        .title{
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
          text-align: center;
        }
        .title-top{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .icons{
          margin-left: 18px;
          .iconTop{
            height: 90px;
            margin-top: 12px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              cursor: pointer;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_bijiben @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              margin-left: 18px;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_taishiji @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_dayinji @2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
          .iconBottom{
            height: 90px;
            margin-top: 18px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_shouji @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_touyingyi @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_其他服务@2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
        }
        .title-center{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .textArea{
          margin-left: 18px;
          margin-top: 12px;
          width: 576px;
          height: 198px;
          background: rgba(216, 216, 216, 0.55);
          border-radius: 8px;
          border: 2px solid #FFFFFF;
          overflow: hidden;
          .TextArea{
            .ant-input{
              min-height: 170px;
              resize: none;
              &::-webkit-input-placeholder{
                font-size: 18px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 27px;
                letter-spacing: 2px;
              }
            }
          }
        }
        .form{
          margin-left: 18px;
          margin-top: 30px;
          .title-bottom{
            float: left;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .input{
            float: left;
            width: 300px;
            height: 36px;
            background: rgba(216, 216, 216, 0.55);
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            overflow: hidden;
            .input-child{
              border: 0px;
              outline: none;
              width: 100%;
              height: 100%;
              background: rgba(216, 216, 216, 0.55);
              &::-webkit-input-placeholder{
                font-size: 16.5px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 33px;
              }
            }
          }
        }
        .button{
          float: left;
          margin-top: 6px;
          margin-left: 150px;
          width: 330px;
          height: 60px;
          text-align: center;
          background: #3B6FEF;
          border-radius: 48px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
        }
      }
    }
  }
  .modalAppointment{
    .ant-modal-content{
      width: 570px;
      height: 417px;
      background: #FFFFFF;
      border-radius: 8px;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #232323;
        line-height: 54px;
      }
      .form-div{
        width: 360px;
        margin-left: 88.5px;
        margin-top: 30px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        height: 36px;
        // line-height: 36px;
        .span{
          float: left;
          margin-top: 6px;
          display: block;
          height: 25px;
          line-height: 25px;
        }
        .inputs{
          float: left;
          display: block;
          width: 255px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #979797;
          overflow: hidden;
          .input{
            outline: none;
            border: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .buttonBox{
        .button{
          margin-left: 201px;
          margin-top: 24px;
          width: 120px;
          height: 42px;
          background: #EB3A32;
          border-radius: 8px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          border: none;
          cursor: pointer;
        }
      }

    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;