.fixBox-mobile{
  z-index: 1;
  top: 100px;
  right: 12px;
  height: 300px;
  position: fixed;
  .box-right{
    .top{
      width: 5.06rem;
      height: 5.08rem;
      background-image: url("https://oss.huizustore.com/dc57de28c2b4419498efe7314dc91adf.png");
      background-size: 100% 100%;
    }
    .box{
      padding: 0;
      margin-top: -1.2rem;
      margin-left: 1rem;
      width: 3.61rem;
      height: 1.27rem;
      .tip{
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 0.9rem;
        color: #FFFFFF;
        line-height: 1.27rem;
      }
    }
    .lease{
      margin-top: 0.5rem;
      width: 5.06rem;
      height: 5.06rem;
      background: red;
      border-radius: 1rem 1rem 0 0;
      padding-top: 1rem;
      border-bottom: 1px solid #fff ;
      .pic{
        margin-left: 1.51rem;
        width: 2.05rem;
        height: 2.05rem;
        background-image: url("https://oss.huizustore.com/0d84ebd1024242289c72a93e4ecd967d.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 0.9rem;
        color: #FFFFFF;
        line-height: 1.27rem;
      }
      .box-lease{
        display: none;
        position: fixed;
        right: 5.5rem;
        top: 13rem;
        width: 23.31rem;
        height: 26.33rem;
        background: url("https://oss.huizustore.com/cc55acda5aaa4486b4347f1cc41b8c39.png");
        background-size: 100% 100%;
        border-radius: 8px;
        text-align: center;
        .title{
          margin-top: 3rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.93rem;
          color: #333333;
          line-height: 2.71rem;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin: 0 auto;
          // margin-top: 1rem;
          width: 14rem;
          height: 14rem;
          background-image: url("https://oss.huizustore.com/ee4a10583fee4bee868a9f51ed99cdc8.jpg");
          background-size: 100% 100%;
        }
        .tip{
          margin-top: 1rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.27rem;
          color: #333333;
          line-height: 1.75rem;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
      }
      cursor: pointer;
      &:hover{
        background: #EB3A32;
        .box-lease{
          display: block;
        }
      }
    }
    .wechat{
      position: relative;
      width: 5.06rem;
      height: 5.06rem;
      padding-top: 1rem;
      border-bottom: 1px solid #fff;
      background: red;
      .pic{
        margin-left: 1.5rem;
        width: 2.05rem;
        height: 2.05rem;
        background-image: url("https://oss.huizustore.com/f543c09afbdf43e383cacd0290c2bd7b.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 0.9rem;
        color: #FFFFFF;
        line-height: 1.27rem;
      }
      cursor: pointer;
      &:hover{
        background: #EB3A32;
        .box-wechat{
          display: block;
        }
      }
      .box-wechat{
        display: none;
        position: fixed;
        right: 5.5rem;
        top: 10rem;
        width: 23.31rem;
        height: 28.8rem;
        background: url("https://oss.huizustore.com/24f41177ff604f26bbbaf0826e36be3a.png");
        background-size: 100% 100%;
        border-radius: 1rem;
        .title{
          margin-top: 2.65rem;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 1.93rem;
          color: #333333;
          line-height: 2.71rem;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .tip{
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.45rem;
          color: #333333;
          line-height: 1.99rem;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin-top: 2rem;
          margin: 0 auto;
          width: 13.98rem;
          height: 13.98rem;
          background-image: url("https://oss.huizustore.com/517c8bfc1bd64dcb8c1199ceed63ddd5.png");
          background-size: 100% 100%;
        }
        .content{
          margin-top: 1rem;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.27rem;
          color: #333333;
          line-height: 1.75rem;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
      }
    }
    .phone{
      position: relative;
      z-index: 1;
      width: 5.06rem;
      height: 5.06rem;
      padding-top: 1rem;
      border-radius: 0 0 1rem 1rem;
      background: red;
      .pic{
        margin-left: 1.5rem;
        width: 2.06rem;
        height: 2.06rem;
        background-image: url("https://oss.huizustore.com/6d2303eef0f6446b99b036941e4b4725.png");
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 0.9rem;
        color: #FFFFFF;
        line-height: 1.27rem;
      }
      &:hover{
        background: #EB3A32;
        .box-phone{
          display: block;
        }
      }
      cursor: pointer;
      .box-phone{
        display: none;
        position: fixed;
        right: 5.5rem;
        top: 22rem;
        width: 21.5rem;
        height: 12.28rem;
        background: url("https://oss.huizustore.com/65679e09572c44c48c1196430d623255.png");
        background-size: 100% 100%;
        padding: 2.66rem 0 0 4.58rem;
        .bottom {
          text-align: start;
          background: transparent;
        }
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;