.modalInquiry{
  padding: 0;
  .ant-modal-content{
    width: 600px;
    height: 498px;
    background: #fff;
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 54px;
      margin: 20px 0 8px 0;
    }
    img {
      margin: 0 auto;
      display: block;
    }
    .content{
      text-align: center;
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      padding-bottom: 60px;
      padding-top: 20px;
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;