.bottom {
    //width: 375px;
    width: 100%;
    // height: 391px;
    background: #222222;
    overflow: hidden;
    &-fold {
        // margin-top: 37px;
        background: rgba(0,0,0,0);
        overflow: hidden;
        .adm-list {
            ::after {
                border-style: none!important;
            }
            &-item {
                padding-left: 0;
                background: #222222!important;
                &-content {
                    background: #222222;
                    width: 338px;
                    height: 47px;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 19px;
                    padding: 18px 0 9px;
                    margin: 0 auto;
                }
            }
            &-body{
                background: #222222;
                border-style: none;
                margin-top: 29px;
            }
        }
        .adm-collapse-panel {
            &-header {
                .adm-list-item-content {
                    border-top: 1px solid rgba(255, 255, 255, 0.7);
                }
            
            }
            &-content {
                .adm-list-item-content {
                    height: unset;
                    padding: 0 0 9px 0;
                    border-style: none;
                    &-main {
                        padding: 0;
                    }
                }
            
            }
        }
        // &-item {
        //     .adm-list-item-content {
        //         color: rgba(255, 255, 255, 1);
        //     }
        // }
        &-row {
            display: flex;
            justify-content: space-between;
            &-content {
                height: 19px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 19px;
            }
            &-box {
                &-iconLeft {
                    width: 75px;
                    height: 75px;
                    margin: 0 auto;
                }
                &-iconCenter {
                    width: 75px;
                    height: 75px;
                    margin: 0 auto;
                }
                &-iconRight {
                    width: 75px;
                    height: 75px;
                    margin: 0 auto;
                }
                &-tip {
                    margin-top: 5px;
                    text-align: center;
                }
            }
        }
        &-info {
            display: flex;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 19px;
            margin-top: 9px;
            &-left {
                min-width: 65px;
            }
        }
        &-first {
            margin: 0;
        }
    }
    &-hr {
        width: 338px;
        height: 1px;
        background: rgba(255, 255, 255, 0.7);
        margin: 0 auto;
    }
    &-foot {
        margin-top: 36px;
        margin-bottom: 66px;
        &-center {
            height: 16px;
            margin-top: 9px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255,255,255,0.1600);
            line-height: 16px;
            text-align: center;
            a {
                color: rgba(255,255,255,0.1600);
            }
        }
    }
}
@primary-color: #0099ff;@font-size-base: 14px;